





























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Home extends Vue {
  login() {
    console.log("Login");
    this.$router.push({ name: "Setup" });
  }
}
